// Import CSS
import styles from '../css/app.pcss';

// Import images
import logoImg from '../img/onemilegrid-logo.png';
import bannerShapeImg from '../img/banner-shape.png';
import carbonBadge from '../img/carbon-neutral-badge.png';

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    
  // Create our vue instance
  const vm = new Vue({
      el: "#page-container",
      components: {
          'global-header': () => import(/* webpackChunkName: "global-header" */ '../vue/components/Global/Header.vue'),
          'home-banner': () => import(/* webpackChunkName: "home-banner" */ '../vue/components/Home/Banner.vue'),
      },
      data: {
      },
      methods: {
      },
      mounted() {
      },
  });
  
  return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}